<template>
  <v-card
    elevation="0"
    class="warehouse-card"
    :to="{
      name: 'WarehouseDetail',
      params: {
        region: region,
        province: province,
        warehouseId: warehouse.warehouseId
      }
    }"
  >
    <v-card-title class="pb-1">
      <ebsn-meta :target="warehouse" path="warehouse_info.NAME"> </ebsn-meta>
    </v-card-title>
    <v-card-text class="text-body-2"
      >{{
        $t(
          "navbar.address." + warehouse.address.addressTypeId + ".list",
          warehouse.address
        )
      }}
    </v-card-text>
    <v-spacer />
    <v-card-actions>
      <img
        v-if="hasDrive"
        alt="Servizio Drive"
        height="25px"
        class="mr-2"
        src="/services-icons/clicca-e-ritira.svg"
      />
      <img
        v-if="hasLocker"
        alt="Servizio Locker"
        height="25px"
        class="mr-2"
        src="/services-icons/locker.svg"
      />
      <v-spacer />
      <router-link
        class="text-caption font-weight-bold text-decoration-none primary--text text--lighten-1"
        :to="{
          name: 'WarehouseDetail',
          params: {
            province: province,
            regionSlug: region.slug,
            regionName: region.name,
            warehouseId: warehouse.warehouseId
          }
        }"
      >
        {{ $t("storeLocator.goToDetail") }}
        <v-icon x-small color="primary lighten-1">$chevronRight</v-icon>
      </router-link>
    </v-card-actions>
  </v-card>
</template>

<script>
import get from "lodash/get";

export default {
  name: "WarehouseCard",
  props: {
    warehouse: { type: Object },
    region: { type: Object },
    province: { type: String }
  },
  computed: {
    srcImg() {
      return get(this.warehouse, "metaData.warehouse_info.IMAGE_WAREHOUSE");
    },

    hasDrive() {
      return get(this.warehouse, "metaData.warehouse_service.DRIVE", false);
    },
    hasLocker() {
      return get(this.warehouse, "metaData.warehouse_service.LOCKER", false);
    }
  }
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"warehouse-card",attrs:{"elevation":"0","to":{
      name: 'WarehouseDetail',
      params: {
        region: _vm.region,
        province: _vm.province,
        warehouseId: _vm.warehouse.warehouseId
      }
    }}},[_c('v-card-title',{staticClass:"pb-1"},[_c('ebsn-meta',{attrs:{"target":_vm.warehouse,"path":"warehouse_info.NAME"}})],1),_c('v-card-text',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.$t( "navbar.address." + _vm.warehouse.address.addressTypeId + ".list", _vm.warehouse.address ))+" ")]),_c('v-spacer'),_c('v-card-actions',[(_vm.hasDrive)?_c('img',{staticClass:"mr-2",attrs:{"alt":"Servizio Drive","height":"25px","src":"/services-icons/clicca-e-ritira.svg"}}):_vm._e(),(_vm.hasLocker)?_c('img',{staticClass:"mr-2",attrs:{"alt":"Servizio Locker","height":"25px","src":"/services-icons/locker.svg"}}):_vm._e(),_c('v-spacer'),_c('router-link',{staticClass:"text-caption font-weight-bold text-decoration-none primary--text text--lighten-1",attrs:{"to":{
          name: 'WarehouseDetail',
          params: {
            province: _vm.province,
            regionSlug: _vm.region.slug,
            regionName: _vm.region.name,
            warehouseId: _vm.warehouse.warehouseId
          }
        }}},[_vm._v(" "+_vm._s(_vm.$t("storeLocator.goToDetail"))+" "),_c('v-icon',{attrs:{"x-small":"","color":"primary lighten-1"}},[_vm._v("$chevronRight")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
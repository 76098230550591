import get from "lodash/get";

export default {
  computed: {
    title() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    headerImage() {
      return get(this.category, "metaData.category_info.HEADER_IMAGE");
    },
    description() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.descr
      );
    }
  }
};

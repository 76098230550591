<template>
  <div class="warehouse">
    <breadcrumb :items="breadCrumbs" :key="category.categoryId" />
    <v-container fluid>
      <h1 class="font-weight-bold">{{ title }}</h1>
      <div class="description mb-3" v-html="description"></div>
      <v-row class="regions">
        <v-col
          cols="12"
          sm="6"
          md="4"
          xl="2"
          v-for="warehouse in warehouseList"
          :key="warehouse.warehouseId"
        >
          <warehouse-card
            :warehouse="warehouse"
            :region="region"
            :province="province"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import category from "~/mixins/category.js";
import categoryTitle from "@/mixins/categoryTitle.js";
import AddressService from "~/service/addressService";
import get from "lodash/get";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import WarehouseCard from "@/components/store/WarehouseCard.vue";

export default {
  components: { Breadcrumb, WarehouseCard },
  name: "StoreLocator",

  mixins: [category, categoryTitle],
  data() {
    return {
      warehouseList: [],
      region: { slug: null, name: null },
      province: null
    };
  },
  computed: {
    breadCrumbs() {
      let breadCrumbs = [
        { to: { name: "Home" }, text: "Homepage", exact: true },
        {
          to: { name: "WarehouseLocator" },
          text: "Punti vendita",
          exact: true
        }
        // {
        //   to: { name: "WarehouseRegion" },
        //   text: this.region.name,
        //   exact: true
        // }
      ];
      if (this.category) {
        breadCrumbs.push({
          to: {
            name: "WarehouseProvince"
          },
          text: this.category.name,
          exact: true
        });
      }
      return breadCrumbs;
    }
  },
  methods: {
    async findWarehouse() {
      let provinceId = get(
        this.category,
        "metaData.template_model.PROVINCE_ID"
      );
      if (provinceId) {
        let data = await AddressService.findWarehouse({
          province_id: provinceId
        });
        this.warehouseList = data.warehouses;
      }
    }
  },
  created() {
    this.findWarehouse();
    this.region.slug = this.$route.params.regionSlug;
    this.region.name = this.$route.params.regionName;
    this.province = this.$route.params.province;
  }
};
</script>
